.profile-container {
    color: #555;
    font-family: 'Helvetica', 'Arial', sans-serif;
}

.profile-loading {
    text-align: center;
    font-size: 1.5em;
    padding: 50px;
    color: #555;
}

.profile-heading {
    color: #d98d35;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
}

.profile-subheading {
    color: #d98d35;
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #d98d35;
    padding-bottom: 5px;
}

.profile-section {
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
}

/* Smaller buttons with theme colors */
.change-address-btn,
.profile-update-btn {
    background-color: #d98d35 !important;
    border: none !important;
    margin-left: 10px;
    font-size: 0.9em;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
}

.change-address-btn:hover,
.profile-update-btn:hover {
    background-color: #c0772f !important;
}

@media (max-width: 768px) {
    .profile-left, .profile-right {
        margin-bottom: 5px;
    }
}
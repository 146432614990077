.items-grid {
    display: grid; /* or display: flex; */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
}

.item-card {
    border: 1px solid #d98d35;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-card-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 12px;
}

.item-card-title {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0;
}

.item-card-sku {
    margin: 4px 0;
    color: #555;
    font-size: 0.9em;
}
/*.item-card-price,
.item-card-quantity {
    margin: 4px 0;
    color: #555;
}*/

.item-card-placeholder {
    width: 100px;
    height: 100px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
    font-size: 0.9em;
}
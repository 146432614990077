/* src/pages/CartPage.css */

.cart-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

.cart-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

/* Grid layout for the item rows */
.cart-header-row, .cart-item-row {
    display: grid;
    grid-template-columns: 80px 1fr 100px 100px 100px 60px 100px 80px;
    align-items: center;
    gap: 10px;
    background: #f9f9f9;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
}

.cart-header-row {
    font-weight: bold;
    background: #ddd;
}

.cart-item-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
}

.cart-item-description {
    font-size: 1em;
}

.cart-item-dates {
    text-align: center;
}

.cart-item-unit-price,
.cart-item-quantity,
.cart-item-total {
    text-align: center;
    font-weight: bold;
}

.cart-item-remove {
    background: #d9534f;
    border: none;
    color: #fff;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
}
.cart-item-remove:hover {
    background: #c9302c;
}

.confirm-booking-button {
    background: #28a745;
    border: none;
    color: #fff;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1em;
    margin-top: 20px;
}
.confirm-booking-button:hover {
    background: #218838;
}

/* Summary box styling */
.cart-summary {
    border: 1px solid #ccc;
    padding: 15px;
    background: #fafafa;
    border-radius: 5px;
}

.cart-summary h2 {
    margin-top: 0;
    margin-bottom: 1em;
    text-align: end;
}

.cart-summary-row {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    font-size: 1em;
}

.cart-summary-total span {
    font-weight: bold;
    font-size: 1.1em;
}

/*
   Two-column layout for "Notes" and "Summary"
   side-by-side (like in the confirmation email).
*/
.cart-lower-section {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

/* Notes Box, styled similarly to the summary box */
.notes-box {
    flex: 1;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 5px;
    padding: 15px;
}

.notes-box h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
}

/* Style the discount code / discount area */
.discount-section {
    margin: 1em 0;
    background: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}

.discount-section label {
    font-weight: bold;
    margin-right: 10px;
}

.discount-section input {
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.discount-section button {
    background-color: #d98d35;
    color: #fff;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

.discount-section button:hover {
    background-color: #b3742b;
}

.discount-applied {
    margin: 1em 0;
    padding: 10px;
    background: #e8ffe8;
    border: 1px solid #8bc98b;
    border-radius: 5px;
    color: #2c662c;
}

.discount-applied button {
    background: #d9534f;
    border: none;
    color: #fff;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 5px;
}
.discount-applied button:hover {
    background: #d9535f;
}

/*
   Example styling for an empty cart or error message
   (optional, if you want them to match the theme)
*/
.empty-cart {
    background: #fffbe6;
    border: 1px solid #ffe58f;
    padding: 15px;
    border-radius: 5px;
    color: #8c6d1f;
    font-weight: bold;
    margin-top: 20px;
}
/* src/components/AdminLayout.css */

.admin-dashboard-title {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

/* Admin Navigation Styles */
.admin-nav .nav-link {
    color: #555;
    font-size: 18px;
    padding: 10px 20px;
}

.admin-nav .nav-link:hover {
    color: #d98d35;
}

.admin-nav .nav-tabs .nav-link.active {
    color: #d98d35;
    border-color: #d98d35 #d98d35 #fff;
}

.admin-nav .nav-item {
    margin: 0 5px;
}

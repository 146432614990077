/* src/pages/StoreInfoPage.css */
.store-info-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
}

.store-info-container h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #555;
}

.store-hours-section,
.store-info-section,
.store-logo-section {
    margin-bottom: 40px;
}

/* Store-hours-table styling */
.store-hours-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.store-hours-table th,
.store-hours-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.store-hours-table th {
    background-color: #f2f2f2;
}

/* Buttons */
.store-info-container button {
    background-color: #d98d35;
    color: #fff;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 10px;
}

.store-info-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.store-info-container button:hover:enabled {
    background-color: #b3742b;
}

.info-field {
    margin: 10px 0;
}

/* Logo controls: place input, button, and preview in a row */
.store-logo-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* The preview image - max width/height so it doesn't stretch the page */
.store-logo-preview {
    max-width: 350px;
    max-height: 350px;
    object-fit: contain; /* keep aspect ratio */
    border: 1px solid #ccc;
    border-radius: 4px;
}
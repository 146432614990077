/* src/pages/AdminUserDetailsPage.css */
.admin-user-details-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.user-info-section {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 20px;
}

.address-section {
    background-color: #f3f3f3;
    padding: 10px;
    margin-bottom: 20px;
}

.update-message {
    margin-top: 10px;
    color: green;
}

/* src/pages/AdminPricingPage.css */

.deposit-section {
    margin-bottom: 20px;
}

.admin-pricing-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.admin-pricing-container h1,
.admin-pricing-container h2 {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
}

.pricing-table th,
.pricing-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.pricing-table th {
    background-color: #f2f2f2;
}

.pricing-table td button {
    background-color: #d9534f;
    color: #fff;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.pricing-table td button:hover {
    background-color: #c9302c;
}

.add-pricing-form {
    max-width: 400px;
    margin: 0 auto;
}

.add-pricing-form label {
    display: block;
    margin-bottom: 10px;
    color: #555;
}

.add-pricing-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.add-pricing-form button {
    background-color: #d98d35;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.add-pricing-form button:hover {
    background-color: #b3742b;
}

/* src/pages/LensPage.css */
.lens-page-container {
    margin: 20px;
}

.lens-page-container h1 {
    margin-bottom: 20px;
    text-align: center;
}

.items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    align-items: start;
    padding: 20px;
}
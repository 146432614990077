/* src/pages/AllItemsPage.css */
.all-items-container {
    margin: 20px auto;
    max-width: 1000px;
    font-family: 'Roboto', 'Arial', sans-serif;
    color: #333;
}

.all-items-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.all-items-search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.all-items-search-container input {
    padding: 6px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    max-width: 300px;
}

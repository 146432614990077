.admin-users-container {
    margin: 20px auto;
    max-width: 1200px;
    font-family: 'Roboto', 'Arial', sans-serif;
    color: #333;
}

.admin-users-container h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 2rem;
    color: #444;
}

/* Filter Row */
.filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.search-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
}

.search-container label {
    font-weight: 500;
    margin-bottom: 5px;
}

.search-input {
    padding: 8px;
    width: 220px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.checkbox-filters {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.checkbox-filters label {
    cursor: pointer;
    font-size: 0.9rem;
}

.admin-users-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    font-size: 0.95em;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.admin-users-table th, .admin-users-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.admin-users-table thead tr {
    background-color: #f7f7f7;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
}

.admin-users-table tbody tr:nth-child(even) {
    background-color: #fafafa;
}

.admin-users-table tbody tr:hover {
    background-color: #f1f1f1;
}

/* Admin button styles */
.make-admin-btn,
.remove-admin-btn,
.view-user-profile-btn {
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 0.85rem;
    transition: background-color 0.2s ease;
}

/* Make Admin (green) */
.make-admin-btn {
    background-color: #28a745;
    color: #fff;
}

.make-admin-btn:hover {
    background-color: #218838;
}

/* Remove Admin (red) */
.remove-admin-btn {
    background-color: #dc3545;
    color: #fff;
}

.remove-admin-btn:hover {
    background-color: #c82333;
}

/* View Profile */
.view-user-profile-btn {
    background-color: #d98d35;
    color: #fff;
}

.view-user-profile-btn:hover {
    background-color: #d98d35;
}
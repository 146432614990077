.admin-items-container {
    margin: 20px auto;
    max-width: 1000px;
    font-family: 'Roboto', 'Arial', sans-serif;
    color: #333;
}

.admin-items-container h1 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 2rem;
    color: #444;
}

/* Controls row at top */
.admin-items-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: center;
}

.control-label {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 0.95rem;
}

.control-label select,
.control-label input {
    margin-top: 5px;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 0.9rem;
}

/* Items list */
.admin-items-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.admin-items-list li {
    display: grid;
    grid-template-columns: 1fr auto auto;
    column-gap: 16px;
    background-color: #fff;
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    align-items: center;
}

.admin-item-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.admin-item-description {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

.admin-item-category {
    font-size: 0.9rem;
    color: #777;
}

.admin-item-timestamp {
    font-size: 0.8rem;
    color: #999;
}

/* Actions column */
.admin-item-actions {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.admin-item-actions a {
    text-decoration: none;
    color: #d98d35;
    font-size: 0.9rem;
    transition: color 0.2s ease;
}

.admin-item-actions a:hover {
    color: #b36825;
}

/* Hide/Unhide button */
.hide-toggle-btn {
    padding: 7px 14px;
    font-size: 0.9rem;
    border: none;
    border-radius: 4px;
    background-color: #d98d35;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-weight: 500;
}

.hide-toggle-btn:hover {
    background-color: #b36825;
}

/* Responsive below 768px */
@media (max-width: 768px) {
    .admin-items-controls {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    /* On mobile, switch to a simpler layout for each item row */
    .admin-items-list li {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }

    .admin-item-actions {
        flex-direction: row;
        gap: 10px;
        margin-top: 10px;
    }
}
/* src/components/common/Header.css */

/*!* Change the color of the dropdown toggle (the Admin link) *!
.navbar-nav .nav-link.dropdown-toggle {
    color: #555; !* Link color *!
}*/

.navbar-nav .nav-link.dropdown-toggle:hover {
    color: #d98d35; /* Highlight color on hover */
}

/* Change the background color of the dropdown menu */
.dropdown-menu {
    background-color: #fff; /* White background */
}

/* Change the color of the dropdown items */
.dropdown-item {
    color: #555; /* Link color */
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #fff !important; /* Text color on hover */
    background-color: #d98d35 !important; /* Highlight background color on hover */
}

/* Remove the focus outline */
.dropdown-item:focus {
    outline: none;
}

/* The cart link has position: relative so the badge can be absolute within it. */
.cart-link {
    position: relative;
    display: inline-flex;  /* ensures icon + text are in a row */
    align-items: center;
    margin-right: 20px;    /* spacing from next item */
}

/* The badge is placed near top-right of the .cart-link container. */
.cart-badge {
    background-color: #d98d35;
    color: #fff;
    border-radius: 50%;
    padding: 0 6px;
    font-size: 0.75rem;

    position: absolute;
    top: -6px;
    right: -12px;  /* adjust as needed */

    /* Optional drop shadow */
    box-shadow: 0 0 2px rgba(0,0,0,0.6);
}

/* Shake animation on hover (optional) */
.cart-link:hover {
    animation: shake 0.3s;
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
}
/* src/pages/HomePage.css */

/* We assume each card is 300px wide, plus 30px gaps between them.
   For 3 cards + 2 gaps, total = 900 + 60 = 960px container width. */
.home-container {
    /* Fixed width for 3 cards + 2 gaps */
    width: 960px;

    /* Remove 'margin: 0 auto' to avoid centering the container */
    /* This will align the container to the left by default */
    margin: 0;

    /* Some padding or styling as desired */
    padding: 40px 20px;
    text-align: center;
    background-color: #f9f9f9;

    /*
      The dynamic transform scale is set inline in HomePage.js, e.g.:
      style={{ transform: `scale(${scale})`, transformOrigin: 'top left' }}
    */
}

/* Page heading */
.home-heading {
    font-size: 2.2rem;
    margin-bottom: 40px;
    color: #333;

    /* If you want the heading text left-aligned as well (optional): */
    /* text-align: left; */
}

/* Flex row for 3 cards, no wrapping */
.home-grid {
    display: flex;
    flex-wrap: nowrap;
    gap: 30px;                /* space between cards */
    justify-content: center;  /* center them if there's leftover space in the container */
    align-items: stretch;     /* same height */
}

/* Each card: fixed 300px width, 410px height */
.home-card {
    width: 300px;
    height: 410px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.home-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

/* Title area */
.home-card-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #b3742b;
    margin-bottom: 12px;
}

/* Image container: fixed height portion for the image */
.home-card-image {
    width: 100%;
    height: 200px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}

.home-card-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
}

/* Description area */
.home-card-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.4;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    text-align: right;
    margin: 0;
}
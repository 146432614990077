/* src/pages/HolidaysPage.css */
.holidays-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.holidays-container h1,
.holidays-container h2 {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

.holidays-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
}

.holidays-table th,
.holidays-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.holidays-table th {
    background-color: #f2f2f2;
}

.delete-button {
    background-color: #d9534f;
    color: #fff;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.delete-button:hover {
    background-color: #c9302c;
}

.add-holiday-form {
    max-width: 400px;
    margin: 0 auto;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    color: #555;
}

.form-group input {
    padding: 8px;
    font-size: 16px;
}

.holidays-container button[type='submit'] {
    background-color: #d98d35;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.holidays-container button[type='submit']:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.holidays-container button[type='submit']:hover:enabled {
    background-color: #b3742b;
}

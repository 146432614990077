/* src/pages/AdminItemDetailsPage.css */

.admin-item-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.admin-item-details-container h1,
.admin-item-details-container h2 {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

.admin-item-details-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.item-image {
    text-align: center;
}

.item-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.item-placeholder {
    width: 100%;
    height: 300px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #555;
    font-size: 1.2em;
}

.pricing-tiers {
    margin-top: 20px;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
}

.pricing-table th,
.pricing-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

.pricing-table th {
    background-color: #f2f2f2;
    color: #333;
}

.pricing-table td {
    color: #555;
}

.details-editor {
    margin-top: 20px;
}

.details-editor .ql-container {
    min-height: 200px;
}

.details-editor button {
    margin-top: 10px;
    background-color: #d98d35;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.details-editor button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.details-editor button:hover:not(:disabled) {
    background-color: #b3742b;
}

.details-editor button {
    /* existing styles */
}

/* Style for the reset button */
.details-editor button:nth-child(3) {
    background-color: #f44336; /* Red color for reset */
}

.details-editor button:nth-child(3):hover:not(:disabled) {
    background-color: #d32f2f; /* Darker red on hover */
}


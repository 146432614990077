/* src/pages/ConfirmationPage.css */

.confirmation-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    /* position: relative;  <-- no longer needed for the logo approach */
}

/* Flex row that holds the <h1> and the logo side by side */
.heading-and-logo {
    display: flex;
    align-items: center;
    justify-content: space-between; /* h1 on left, img on right */
    margin-bottom: 5px;           /* small spacing before the next paragraph */
}

/* The store logo, made smaller so it doesn't overshadow the heading */
.confirmation-logo {
    max-width: 350px;
    max-height: 175px;
    object-fit: contain;
    margin-left: 0px; /* gap from the heading if needed */
}

.confirmation-container h1 {
    font-size: 2em;
    margin: 0; /* no bottom margin, we rely on heading-and-logo's spacing */
}

.confirmation-container h2 {
    margin-top: 20px;
}

.confirmation-container p {
    font-size: 1em;
    margin-bottom: 10px;
}

.confirmation-container ul {
    list-style: disc;
    margin-left: 20px;
    margin-top: 10px;
}

.confirmation-container li {
    margin-bottom: 5px;
}
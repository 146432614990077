/* src/pages/AdminDiscountsPage.css */

.admin-discounts-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background: #f9f9f9;
    border-radius: 5px;
}

.admin-discounts-title {
    color: #d98d35;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2em;
}

.admin-discounts-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.admin-discounts-table thead {
    background: #d98d35;
    color: #fff;
}

.admin-discounts-table th, .admin-discounts-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 0.9em;
}

.admin-discounts-table tr:nth-child(even) {
    background: #f2f2f2;
}

.loading-text, .error-text {
    text-align: center;
    font-size: 1.2em;
    color: #333;
}
/* src/pages/AdminHomePage.css */

.admin-home-container {
    text-align: center;
    padding: 50px;
}

.admin-home-container h1 {
    color: #555;
    margin-bottom: 20px;
}

.admin-home-container p {
    font-size: 18px;
    color: #777;
}

/* Add this block to style links */
.admin-home-container a {
    color: #555;
    text-decoration: none;
}

.admin-home-container a:hover {
    color: #d98d35; /* Highlight color on hover */
    text-decoration: underline;
}
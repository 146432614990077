/* src/pages/AdminBookingsCalendar.css */

.admin-bookings-calendar {
    padding: 20px;
}

.admin-bookings-calendar h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
}

.fc .fc-toolbar-title {
    color: #555;
    font-size: 1.5em;
}

.fc-daygrid-event {
    background-color: #d98d35;
    border: none;
    color: #fff;
    padding: 2px 4px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85em;
}

.fc-daygrid-event:hover {
    background-color: #d98d35;
}

.fc-daygrid-day-number {
    color: #555;
    padding: 4px;
}

.fc .fc-button {
    background-color: #d98d35;
    border: none;
    color: #fff;
    font-size: 0.9em;
    padding: 6px 12px;
    margin: 0 2px;
    border-radius: 4px;
}

.fc .fc-button:hover {
    background-color: #d98d35;
}

.fc .fc-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.fc .fc-toolbar.fc-header-toolbar {
    color: #000;
    margin-bottom: 20px;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: #eaf6ff;
}
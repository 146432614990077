/* Footer.css */
.footer-container {
    display: flex; /* Enable Flexbox */
    justify-content: space-between; /* Space out the copyright and links */
    align-items: center; /* Align items vertically */
    width: 100%; /* Ensure it spans the full width */
    color: #000; /* Text color */
    padding: 10px 0; /* Optional padding */
}

.footer-copyright {
    margin: 0; /* Remove default margins */
    font-size: 0.9rem; /* Adjust font size */
}

.footer-links {
    display: flex; /* Use Flexbox for links */
    gap: 15px; /* Space between links */
}

.footer-links a {
    color: #000; /* Ensure links are white */
    text-decoration: none; /* Remove underline */
}

.footer-links a:hover {
    color: #555;
    text-decoration: underline; /* Optional hover effect */
}

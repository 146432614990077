/* src/components/BookingDetailsModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    position: relative;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.modal-content h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.5em;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #aaa;
}

.close-button:hover {
    color: #000;
}

.bookings-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.bookings-table th,
.bookings-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 0.9em;
}

.bookings-table th {
    background-color: #f2f2f2;
}

.bookings-table tr:nth-child(even) {
    background-color: #fafafa;
}

.bookings-table tr:hover {
    background-color: #f1f1f1;
}

.bookings-table td button {
    padding: 5px 10px;
    background-color: #d9534f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.85em;
}

.bookings-table td button:hover {
    background-color: #c9302c;
}

.bookings-table td button:focus {
    outline: none;
}

@media (max-width: 600px) {
    .modal-content {
        width: 95%;
        padding: 15px 20px;
    }

    .bookings-table th,
    .bookings-table td {
        font-size: 0.8em;
        padding: 6px;
    }

    .modal-content h2 {
        font-size: 1.3em;
    }
}